import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Add this import
import { useOidcAccessToken } from "@axa-fr/react-oidc";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "http://localhost:5256/";
console.log("API URL:", API_BASE_URL);

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const setupInterceptors = (accessToken, navigate) => {
  api.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

export const useApi = () => {
  const { accessToken } = useOidcAccessToken();
  const navigate = useNavigate();

  useEffect(() => {
    debugger;
    setupInterceptors(accessToken, navigate);
  }, [accessToken, navigate]);

  return api;
};

export const getHospitalList = async () => {
  try {
    console.log(api.baseURL);
    const response = await api.get("/api/Hospital/GetAllHospitalsForDdlList");
    console.log("getDepartmentList", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getDepartmentList = async (hospitalId) => {
  try {
    console.log(api.baseURL);
    const response = await api.get(
      "/api/Hospital/GetDepartmentListbyHospitalId?HospitalId=" + hospitalId
    );
    console.log("getDepartmentList", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getHospitalDepartmentReport = async (
  hospitalId,
  departmentId,
  fromDate,
  toDate
) => {
  try {
    debugger;
    console.log(api.baseURL);
    const response = await api.get(
      "/api/Report/GetHospitalDepartmentReports?hospitalId=" +
        parseInt(hospitalId) +
        "&departmentId=" +
        parseInt(departmentId) +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate
    );
    console.log("getDepartmentList", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
