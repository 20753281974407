const configuration = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: window.location.origin + "/signin-oidc",
  silent_redirect_uri:
    window.location.origin + "/authentication/silent-callback", // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
  scope: "openid profile roles CaiAudioConverta",
  authority: process.env.REACT_APP_IDENTITY_BASE_URL,
  service_worker_relative_url: null,
  service_worker_only: false,
  refresh_time_before_tokens_expiration_in_second: 3600, // Refresh 1 minute before expiration
};

export default configuration;
