import { useOidcUser } from "@axa-fr/react-oidc";
import { useEffect, useState } from "react";
import UnauthorizedPage from "../Pages/UnauthorizedPage";
import Loader from "./Loader";

const RoleBasedSecure = ({ roles, children }) => {
  const { oidcUser } = useOidcUser();
  const [isLoading, setIsLoading] = useState(true);
  const userRoles = oidcUser?.role || [];
  const hasRequiredRole = roles.some((role) => userRoles.includes(role));

  useEffect(() => {
    // Check if roles have been fetched
    if (oidcUser) {
      setIsLoading(false);
    }
  }, [oidcUser]);

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    ); // You can customize this loader as needed
  }

  // Render child components if the user has the required role, otherwise show UnauthorizedPage
  return hasRequiredRole ? <>{children}</> : <UnauthorizedPage />;
};

export default RoleBasedSecure;
