import React from "react";
import logo from "../assets/img/logo.jpg";
import logoutlogo from "../assets/img/logout.jpg";
import { Link } from "react-router-dom";
import { useOidc, useOidcUser } from "@axa-fr/react-oidc";

function Header() {
  const { oidcUser } = useOidcUser();
  const { logout, isAuthenticated } = useOidc();
  return (
    <header>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="logo">
              <img src={logo} width="" height="" alt="" loading="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="UsernameWrp">
              <ul>
                <li>
                  <span className="userName">
                    {oidcUser && oidcUser.given_name}
                  </span>
                </li>
                <li>
                  <a>
                    {isAuthenticated && (
                      <span onClick={() => logout()}>
                        <img
                          src={logoutlogo}
                          width=""
                          height=""
                          alt=""
                          loading=""
                        />
                        Logout
                      </span>
                    )}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
