import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProcessingReport from "./Pages/ProcessingReport";
import UnauthorizedPage from "./Pages/UnauthorizedPage";
import configuration from "./Utlis/oidc-config";
import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc";
import VerticalMenu from "./Component/VerticalMenu";
import RoleBasedSecure from "./Component/RoleBasedSecure";
import Header from "./Component/Header";

function App() {
  return (
    <div className="App">
      <OidcProvider
        configuration={configuration}
        authenticatingComponent={() => null}
        callbackSuccessComponent={() => null}
      >
        <OidcSecure>
          <Header></Header>
          <div className="main-container">
            <RoleBasedSecure roles={["ReportManager"]}>
              <div className="navcontainer">
                <VerticalMenu />
              </div>
              <div className="main">
                <Router>
                  <Routes>
                    <Route path="/" element={<ProcessingReport />} />
                  </Routes>
                </Router>
              </div>
            </RoleBasedSecure>
          </div>
        </OidcSecure>
      </OidcProvider>
    </div>
  );
}

export default App;
